<template>
  <el-dialog v-dialogDrag title="修改信息" :visible.sync="dialogVisible" width="540px" :before-close="handleClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="自提车号" prop="carCode">
            <el-input v-model="form.carCode" placeholder="请输入自提车号"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item label="证件号" prop="papers">
            <el-input v-model="form.papers" placeholder="请输入证件号"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item label="提货人电话" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入提货人电话"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item label="提货人" prop="consignee">
            <el-input v-model="form.consignee" placeholder="请输入提货人"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item label="提货时间" prop="pickupTime">
             <el-date-picker
              value-format="timestamp"
              v-model="form.pickupTime"
              type="date"
              placeholder="请选择提货时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-input v-model="carCode" placeholder="请输入内容"></el-input> -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    // 手机号
    var validateNum = (rele, value, callback) => {
       let phone = this.form.phone;
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else if (!/^1[3456789]\d{9}$/.test(phone)) {
          // this.$refs.appealForm.validateField("phoneNum");
        callback(new Error("请输入正确的手机号"));
      }else{
        callback()
      }
    }
    // 正整数
     var Num = (rele, value, callback) => {
      if (!/^[1-9]+[0-9]*$/.test(value)) {
          // this.$refs.appealForm.validateField("phoneNum");
        callback(new Error("请输入正确的手机号"));
      }else{
        callback()
      }
    }
    return {
      dialogVisible: false,
      id: null,
      data: null,
      form: {
        carCode: '',
        papers: '',
        phone: '',
        consignee: '',
        pickupTime: ''
      },
      rules: {
        carCode: [
          { required: true, message: "请输入自提车号", trigger: "change" }
        ],
        papers: [
          { required: true, message: "请输入证件号", trigger: "change" },
          { validator: Num, message: "格式错误", trigger: "blur"}
        ],
        phone: [
          { required: true, message: "请输入提货人电话", trigger: "change" },
          { validator: validateNum, message: "手机号格式错误", trigger: "blur"}
        ],
        consignee: [
          { required: true, message: "请输入提货人", trigger: "change" }
        ],
        pickupTime: [
          { required: true, message: "请选择提货时间", trigger: "change" }
        ],
      }
    };
  },
  methods: {
    save () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_plateNumber.param = this.form
          protocolFwd.param_plateNumber.param.deliveryId = this.data.deliveryId
          http.postFront(protocolFwd.param_plateNumber).then(response => {
            let {value, code, message} = response.data
            if (code == 0) {
              this.$EL_MESSAGE('修改成功')
              this.dialogVisible = false
              this.carCode = ''
              this.$emit('upDate')
            }
          })
        }
      })
    },
    show (id) {
      console.log('子组件', id)
      this.dialogVisible = true
      this.data = id
      this.form.carCode = id.carCode
      this.form.phone = id.phone
      this.form.papers = id.papers
      this.form.consignee = id.consignee
      this.form.pickupTime = id.pickupTime
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.input = ''
          this.form = {
            carCode: '',
            papers: '',
            phone: '',
            consignee: '',
            pickupTime: ''
          },
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-date-editor {
  width: 100%;
} 
</style>