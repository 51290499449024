<template>
  <div class="tableList">
    <el-table :data="tableDatas">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="deliveryCode" label="取货编码"></el-table-column>
      <el-table-column prop="holdCode" label="入库单编码" width="90"></el-table-column>
      <el-table-column prop="className" label="商品分类" min-width="80"></el-table-column>
      <!-- <el-table-column prop="quantity" label="数量" min-width="50"></el-table-column> -->
      <el-table-column prop="specifications" label="商品属性" min-width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.specifications | firstAttributeTransformate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="重量" min-width="70">
        <template
          slot-scope="scope"
        >{{ scope.row.weight }}{{scope.row.weight!=null? scope.row.unit:''}}</template>
      </el-table-column>
      <el-table-column prop="storageName" label="交收仓库"></el-table-column>
      <el-table-column prop="carCode" label="提货人车牌号" width="110px"></el-table-column>
      <el-table-column prop="consignee" label="提货人"></el-table-column>
      <el-table-column prop="phone" label="提货人电话"></el-table-column>
      <el-table-column prop="pickupTime" label="提货时间">
        <template slot-scope="scope">
          <span>{{ scope.row.pickupTime | DateFormateDay }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status | pickupGoodsSelfStatusFormate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop label="操作" width="130" fixed="right">
        <template slot-scope="scope" style="display:flex">
          <el-button type="primary" size="mini" :disabled="scope.row.status == 1 ? true : false" @click="showAlterDialog(scope.row)" style="width: 102px!important;margin-bottom: 5px!important;">修改</el-button>
          <el-button type="warning" size="mini" @click="showDialog(scope.row.deliveryId)" style="margin-left: 0!important">打印自提单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-dialogDrag
      title="自提单"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="1300px"
      :before-close="handleClose"
    >
      <div class="pickupCard" id="printContent">
        <div style="width:90%;margin:0 auto;">
        <!-- <p class="title" style="font-size: 16px; text-align: center; color: #000000;">江西三朵云B2B交易系统有限责任公司交易商自提仓库出库单</p> -->
        <p class="title" style="font-size: 25px; text-align: center; color: #000000;">三朵云国际交收仓库自提出库单</p>
        <!-- <div
          style="display:flex;justify-content: flex-end;font-weight:700;margin: 20px 0"
        >出库单编号:{{ details.deliverCode ? details.deliverCode : '--' }}</div>
        <div class="content" style=" margin-top: 20px;text-align: center; color: #000000;">
          <table border="1" cellspacing="0" cellpadding="0" style="width: 100%;height: 100%">
            <tr align="center">
              <th style="padding: 10px;">入库单编码</th>
              <th style="width: 200px">仓库名称</th>
              <th style="width: 100px">数量</th>
              <th>重量</th>
              <th>入库日期</th>
              <th>出库日期</th>
            </tr>
            <tr align="center">
              <td style="padding: 10px;width:155px">{{details.holdCode ? details.holdCode: '---'}}</td>
              <td>{{details.name ? details.name : '---'}}</td>
              <td>{{details.quantity ? details.quantity : '---'}}</td>
              <td>{{details.deliveryWeight ? details.deliveryWeight : '---'}}{{details.unit ? details.unit : ''}}</td>
              <td>{{details.realPutWarehousTime | DateFormateDay}}</td>
              <td>{{details.pickupTime | DateFormateDay}}</td>
            </tr>
            <tr align="center">
              <th style="padding: 10px;" colspan="1">商品名称</th>
              <td colspan="1">{{details.className ? details.className : '---'}}</td>
              <th col>商品属性</th>
              <td colspan="3" style="padding: 10px">{{details.specificationsName | spec}}</td>
            </tr>
            <tr align="center">
              <th style="padding: 10px;" colspan="1">提货人姓名</th>
              <td colspan="1">{{details.consignee ? details.consignee : '---'}}</td>
              <th colspan="1">身份证号码</th>
              <td colspan="3">{{details.papers ? details.papers : '---'}}</td>
            </tr>
            <tr align="center">
              <th style="padding: 10px;" colspan="1">自提车号</th>
              <td colspan="1">{{details.carCode ? details.carCode : '---'}}</td>
              <th colspan="1">提货人电话</th>
              <td colspan="3">{{details.phone ? details.phone : '---'}}</td>
            </tr>
            <tr align="center">
              <th style="padding: 10px;">仓库地址</th>
              <td colspan="5">{{details.address ? details.address : '---'}}</td>
            </tr>
            <tr align="center">
              <th style="padding: 10px;">仓库联系人</th>
              <td colspan="5">{{details.responsibleName ? details.responsibleName : '---'}}</td>
            </tr>
            <tr align="center">
              <th style="padding: 10px;">仓库电话</th>
              <td colspan="5">{{details.responsiblePhone ? details.responsiblePhone : '---'}}</td>
            </tr>
          </table> -->
        <div
          style="display:flex;justify-content: flex-end;font-weight:700;margin: 20px 0"
        >出库单编号:{{ details.deliverCode ? details.deliverCode : '--' }}</div>
        <div class="content" style=" margin-top: 20px;text-align: center; color: #000000;">
          <div class="list" style="height: 35px;line-height: 35px;overflow: hidden;border-bottom: 1px solid #000000;border-top: 1px solid #000000;">
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">商品名称</div>
            <div style="width: 55%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">商品属性</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">入库日期</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;border-right: 1px solid #000000;">出库日期</div>
          </div>
          <div class="list" style="height: 35px;line-height: 35px;overflow: hidden;border-bottom: 1px solid #000000;">
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;">{{details.className ? details.className : '---'}}</div>
            <div style="width: 55%; float: left; border-left: 1px solid #000000; box-sizing: border-box;">{{details.specificationsName | spec}}</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;">{{details.realPutWarehousTime | DateFormateDay}}</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;border-right: 1px solid #000000;">{{details.pickupTime | DateFormateDay}}</div>
          </div>
          <div class="list" style="height: 35px;line-height: 35px;overflow: hidden;border-bottom: 1px solid #000000;" v-for="(item,index) of details.holdCodesWeights" :key="index">
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">入库单编码</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">{{item.holdCode ? item.holdCode : '---'}}</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">重量</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;border-right: 1px solid #000000;font-weight:700;">{{item.deliverWeight ? item.deliverWeight : '---'}}</div>
          </div>
           <div class="list" style="height: 35px;line-height: 35px;overflow: hidden;border-bottom: 1px solid #000000;">
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">提货人名称</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;">{{details.consignee ? details.consignee : '---'}}</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">身份证号码</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;border-right: 1px solid #000000;">{{details.papers ? details.papers : '---'}}</div>
          </div>
           <div class="list" style="height: 35px;line-height: 35px;overflow: hidden;border-bottom: 1px solid #000000;">
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">自提车号</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;">{{details.carCode ? details.carCode : '---'}}</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">提货人电话</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;border-right: 1px solid #000000;">{{details.phone ? details.phone : '---'}}</div>
          </div>
           <div class="list" style="height: 35px;line-height: 35px;overflow: hidden;border-bottom: 1px solid #000000;">
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">仓库名称</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;">{{details.name ? details.name : '---'}}</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">仓库地址</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;border-right: 1px solid #000000;">{{details.address ? details.address : '---'}}</div>
          </div>
           <div class="list" style="height: 35px;line-height: 35px;overflow: hidden;border-bottom: 1px solid #000000;">
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">仓库联系人</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;">{{details.responsibleName ? details.responsibleName : '---'}}</div>
            <div style="width: 15%; float: left; border-left: 1px solid #000000; box-sizing: border-box;font-weight:700;">仓库电话</div>
            <div style="width: 35%; float: left; border-left: 1px solid #000000; box-sizing: border-box;border-right: 1px solid #000000;">{{details.responsiblePhone ? details.responsiblePhone : '---'}}</div>
          </div>
          <div style="display: flex;margin-top:90px;width:100%">
            <div style="width: 33.33%;font-weight:700">
              提货人（签名）：
              <span
                style="display: inline-block;
            border-bottom: 1px solid #000;
            width: 150px;"
              ></span>
            </div>
            <div style="width: 33.33%;font-weight:700">
              仓库经办人（签名）：
              <span
                style="display: inline-block;
                border-bottom: 1px solid #000;
                width: 150px;"
              ></span>
            </div>
            <div style="width: 33.33%;font-weight:700">
              仓库签章：
              <span
                style="display: inline-block;
                      border-bottom: 1px solid #000;
                      width: 150px;"
              ></span>
            </div>
          </div>
        </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="print()">打 印</el-button>
      </span>
    </el-dialog>
    <!-- 变更自提车号 -->
    <alterDialog ref="alter" @upDate="upData"></alterDialog>
  </div>
</template>
<script>
import alterDialog from "./components/AlterDialog";
export default {
  components: {
    alterDialog,
  },
  filters: {
    spec(value) {
      if (value != "" && value != null) {
        let a = JSON.parse(value);
        let arr1 = Object.keys(a);
        let arr2 = Object.values(a);
        let b = "";
        for (let i = 0; i < arr1.length; i++) {
          b = b + arr1[i] + ":" + arr2[i] + ";";
        }
        return b;
      } else {
        return '---'
      }
    },
  },
  created() {
    for (let i = 0; i < this.tableDatas.length; i++) {
      if (!this.tableDatas[i].holdCode) {
        this.tableDatas[i].holdCode = '---'
      }
    }
  },
  props: {
    tableDatas: {
      type: Array,
      default: function () {
        return [];
      },
    },
    userType: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      details: {},
    };
  },
  methods: {
    upData() {
      this.$emit("upData");
    },
    // 修改
    showAlterDialog(id) {
      this.$refs.alter.show(id);
    },
    handleClose() {
      this.dialogVisible = false;
      this.details = {};
    },
    showDialog(id) {
      
      protocolFwd.param_querySelfPickUpOrder.param.deliveryId = id;
      http
        .postFront(protocolFwd.param_querySelfPickUpOrder)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.details = value;
            let that = this
            protocolFwd.param_findDeliveryDetail.param.deliveryId = id;
            http
              .postFront(protocolFwd.param_findDeliveryDetail)
              .then((response) => {
                const { code, message, value } = response.data;
                if (code == 0) {
                  if(value.content.length!=0){
                    let holdCodesWeights = []
                    for(let i=0;i<value.content.length;i++){
                      let obj = {}
                      obj.holdCode = value.content[i].holdCode
                      obj.deliverWeight = value.content[i].deliveryWeight
                      holdCodesWeights.push(obj)
                    }
                   that.details.holdCodesWeights = holdCodesWeights
                  }else{
                    let obj = {}
                    let arrays = []
                    obj.holdCode = that.details.holdCode
                    obj.deliverWeight = that.details.deliveryWeight+that.details.unit+''
                    arrays.push(obj)
                    that.details.holdCodesWeights = arrays
                  }
                  that.dialogVisible = true;
                  // this.details = value;
                } else {
                  this.$EL_MESSAGE(message);
                }
              });
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    print() {
      var printBox = document.getElementById("printContent");
      var newContent = printBox.innerHTML;
      document.body.style.display = 'flex'
      document.body.style.justifyContent = 'center'
      document.body.style.alignItems = 'center'
      document.body.style.height = '100vh'
      var oldContent = document.body.innerHTML;
      document.body.innerHTML = newContent;
      window.print();
      window.location.reload();
      document.body.innerHTML = oldContent;
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}
.pickupCard .title {
  font-size: 16px;
  text-align: center;
  color: #000000;
}
.pickupCard .content {
  margin-top: 20px;
  // border-top: 1px solid #000000;
  // border-right: 1px solid #000000;
  text-align: center;
  color: #000000;
}
.pickupCard .content .list {
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  border-bottom: 1px solid #000000;
}
.pickupCard .content .list div {
  float: left;
  border-left: 1px solid #000000;
  box-sizing: border-box;
}
</style>